import axios from '../instance'

export const getWarehouseNotSyncList = async (params: any) => {
	try {
		const { data } = await axios.get('/warehouse/sync', {
			params: { ...params },
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const unSyncMarketplace = async (payload: {marketplaceId:string, marketplaceType: string}): Promise<any> => {
	try {
		const { data } = await axios.post('/warehouse/un-sync', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
