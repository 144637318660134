import { IMarketplaceInfo } from '@/type/marketplace'
import axios from '../instance'
import { MarketplaceShop, IQueSync, IAddShopBody } from './marketplace.type'
export const findMarketplacesInShop = async (params: {
	shopId: string,
	marketplaceType?: string,
}): Promise<IMarketplaceInfo[]> => {
	try {
		const { data } = await axios.get('/marketplace', { params })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const marketplaceAuthService = async (params: {
	marketplace: string
	shopId: string
}): Promise<string> => {
	try {
		const { data } = await axios.get('/marketplace/auth', { params })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const importShop = async (params: {
	code: string,
	state: string,
	shopId:string,
}): Promise<MarketplaceShop[]> => {
	try {
		const { data } = await axios.get('/marketplace/import', {
			params,
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const addMarketplaceShop = async (body: IAddShopBody): Promise<MarketplaceShop[]> => {
	try {
		const { data } = await axios.post('/marketplace/add', body)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getProductAfterSync = async (body: {
	shopId: string
	marketplaceType: string
	marketplaceShopId: string
	accessToken: string
}): Promise<{ totalProduct: number }> => {
	try {
		const { data } = await axios.post('/marketplace/products', body)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const productQueApi = async (params: {
	shopId:string
}): Promise<IQueSync[]> => {
	try {
		const { data } = await axios.get('/marketplace/que', {
			params: { ...params },
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getQueSyncById = async (id: string): Promise<IQueSync> => {
	try {
		const { data } = await axios.get(`/marketplace/que/${id}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const importProductMarketplaceList = async (body: {
	shopId: string
	marketplaceIds: string[]
}): Promise<IQueSync> => {
	try {
		const { data } = await axios.post('/marketplace/sync-product', body)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}


export const getSellerWithMarketplaceType = async (body: {
	shopId: string
	marketplaceTypes: string[]
}): Promise<IMarketplaceInfo[]> => {
	try {
		const { data } = await axios.post('/marketplace/sellers', body)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getProductTotalSyncApi = async (params: {
	sellers: string[]
}): Promise<number> => {
	try {
		const { data } = await axios.get('/marketplace/total-sync',{
			params: { ...params },
		} )
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}